import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import { colors } from "../components/colors/colors"

const PresLink = styled(Link)`
  text-decoration: none;
  width: 20%;
  text-align: left;
  margin: 20px;
  > div {
    margin-top: 20px;
    font-family: "benton-sans";
    color: ${colors.white};
    font-size: 18px;
  }

  @media (max-width: 1000px) {
    width: 70%;
    margin: 0 auto;
  }
`
const CardImage = styled(Img)`
  width: 100%;
`

const PresCard = ({ title, image, link, presTitle }) => {
  return (
    <PresLink
      to={`/${link}`}
      state={{
        fromPres: true,
        pathBack: `/presentations/${presTitle}`,
      }}
    >
      <CardImage fluid={image.asset.fluid} alt={title} />
      <div>{title}</div>
    </PresLink>
  )
}

export default PresCard
