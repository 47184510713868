import React from 'react';
import './landingLogo.css'

const LandingLogo = () => {
    return ( 
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 70.21 99.28">
        <title>Asset 1</title>
        <g id="Layer_2" data-name="Layer 2">
          <g id="Layer_1-2" data-name="Layer 1">
            <path
              className="cls-1"
              d="M35,36.85C28.28,40,19.22,42.91,12,47.8c6.3,4.66,14.44,7.29,21.11,10C40,54.41,49,51.42,55.94,46.52,49.21,42.36,41.32,39.6,35,36.85Z"
            />
            <path className="cls-2" d="M23.69,54.05l-1.1-.49Z" />
            <path className="cls-2" d="M25,54.6a8.4,8.4,0,0,0-1.22-.49C24.24,54.29,24.67,54.48,25,54.6Z" />
            <path
              className="cls-2"
              d="M21,52.88c-.42-.18-.85-.42-1.28-.61C20.14,52.46,20.57,52.7,21,52.88Z"
            />
            <path className="cls-2" d="M13.16,48.54,12,47.74C12.43,48,12.79,48.29,13.16,48.54Z" />
            <path
              className="cls-2"
              d="M14.32,49.33a5.48,5.48,0,0,1-1-.67C13.65,48.91,14,49.09,14.32,49.33Z"
            />
            <path
              className="cls-2"
              d="M17,50.86c-.79-.42-1.65-.91-2.38-1.4C15.36,50,16.16,50.44,17,50.86Z"
            />
            <path className="cls-2" d="M22.22,53.44l-1-.49Z" />
            <path className="cls-2" d="M19.65,52.21a7.18,7.18,0,0,1-1.17-.61A7.18,7.18,0,0,0,19.65,52.21Z" />
            <path className="cls-2" d="M18.24,51.54,17.14,51Z" />
            <path
              className="cls-3"
              d="M23.93,26.69c0-5,4.29-8.69,10.78-8.69,5.75,0,9.3,3.73,9.73,8.26a1,1,0,0,0,1,.92L65,27.05a1.07,1.07,0,0,0,1-1h0C65.68,11.38,57.72,0,34.22,0,11.38,0,1.9,12.24,1.9,27.05c0,10,4.28,16.22,10.16,20.63,7.22-4.9,16.34-7.77,23-10.89C28.65,34,23.93,31.16,23.93,26.69Z"
            />
            <path className="cls-2" d="M45.29,41.13l1.29.55Z" />
            <path className="cls-2" d="M27.42,55.58l-.61-.25Z" />
            <path className="cls-2" d="M26.26,55.09l-.86-.37Z" />
            <path className="cls-2" d="M21.18,53,21,52.82Z" />
            <path
              className="cls-2"
              d="M33.11,57.78c-1.83-.73-3.73-1.47-5.69-2.26C29.38,56.31,31.28,57.05,33.11,57.78Z"
            />
            <path className="cls-2" d="M22.52,53.56l-.3-.12Z" />
            <path className="cls-2" d="M26.81,55.33l-.55-.24Z" />
            <path className="cls-2" d="M25.4,54.78,25,54.6Z" />
            <path className="cls-2" d="M23.81,54.11l-.18-.06Z" />
            <path className="cls-2" d="M42.36,39.91l.3.12Z" />
            <path className="cls-2" d="M18.48,51.66l-.24-.12Z" />
            <path className="cls-2" d="M13.34,48.66l-.18-.12Z" />
            <path className="cls-2" d="M17.14,51,17,50.93Z" />
            <path
              className="cls-2"
              d="M14.57,49.46a.32.32,0,0,0-.25-.13C14.38,49.4,14.51,49.4,14.57,49.46Z"
            />
            <path className="cls-2" d="M55.7,46.4l.12.06-.12-.06Z" />
            <path
              className="cls-3"
              d="M55.88,46.46C49,51.29,40,54.35,33.11,57.78h0c7.23,2.94,12.8,6,12.8,12,0,5.2-3.86,10-11.69,10-7.41,0-11-4.65-11.39-9.79a1.06,1.06,0,0,0-1-1H1a1,1,0,0,0-1,1H0C.37,84.47,9,99.28,34.71,99.28c26.5,0,35.5-16.16,35.5-29.5C70.27,58.39,63.9,51.42,55.88,46.46Z"
            />
            <path className="cls-3" d="M32.32,38l.12-.06Z" />
            <path className="cls-3" d="M33.66,37.46l.19-.06Z" />
            <path
              className="cls-4"
              d="M65.92,30.79a1,1,0,0,0-1-1.1H44.68a1.14,1.14,0,0,0-.92.55v.06c-1.4,2.63-4.59,4.65-8.63,6.55C41.38,39.6,49.27,42.36,56,46.52,57.11,45.6,65.06,40.58,65.92,30.79Z"
            />
            <path
              className="cls-4"
              d="M12.06,47.74C9.43,49.46,1.16,55.58.24,65.25a1.08,1.08,0,0,0,.92,1.16H22.65a1.15,1.15,0,0,0,.92-.55h0V65.8c1.28-3.24,4.89-5.69,9.48-8h0C26.38,55.09,18.3,52.46,12.06,47.74Z"
            />
            <path className="cls-3" d="M41.32,54.11l-.31.12Z" />
            <path className="cls-3" d="M35,36.85l-1.22.55Z" />
            <path className="cls-3" d="M32.44,38l1.22-.55Z" />
          </g>
        </g>
      </svg>
     );
}
 
export default LandingLogo;