import React from "react"
import styled from "styled-components"
import { graphql, Link } from "gatsby"
import BlockContent from "@sanity/block-content-to-react"
import Layout from "../components/layout"
import { colors } from "../components/colors/colors"

import PresCard from "../components/pres-cards"
import LandingLogo from "../components/SVGs/landingLogo"
import BannerImage from "../components/presentation-components/bannerImage"

const Presentation = styled.div`
  margin: 0;
  padding: 0 0 200px 0;
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-flow: wrap column;
  background: #000;
`

const TextBox = styled.div`
  margin: 25px 0 25px 0;
  width: 100%;
  text-align: left;
  color: ${colors.white};

  > div {
    width: 80%;
    max-width: 1000px;
    margin: 0 auto;
    text-align: left;
    font-family: "benton-sans";
    line-height: 1.2em;
    font-size: 22px;
  }

  @media (max-width: 1000px) {
    height: auto;
    > p {
      width: 100%;
      padding: 10px;
    }
  }
`
const LogoHolder = styled.div`
  height: 75px;
  width: 75px;
  margin: 50px auto 100px auto;
`

const ProjectDiv = styled.div`
  width: 100%;
  max-width: 1000px;
  color: ${colors.white};
  background: #000;
  margin: 25px auto;
  display: flex;
  flex-flow: wrap row;
  justify-content: flex-start;

  @media (max-width: 1000px) {
    flex-flow: wrap column;
    justify-content: center;
  }
`

const serializers = {
  types: {
    block(props) {
      switch (props.node.style) {
        case "h1":
          return <h1 className="title is-1">{props.children}</h1>
        case "h2":
          return <h2 className="title is-2">{props.children}</h2>
        case "h3":
          return <h3 className="title is-3">{props.children}</h3>
        case "h4":
          return <h4 className="title is-4">{props.children}</h4>
        case "blockquote":
          return <blockquote>{props.children}</blockquote>
        case "div":
          return (
            <div style={{ width: "100%", textAlign: "center" }}>
              <h2>{props.children}</h2>
            </div>
          )
        default:
          return <p>{props.children}</p>
      }
    },
  },
}
const PresentationPost = ({ data }) => {
  const source = data.sanityPresentation

  const makeCards = (project, slug) => {
    const fullPath = `${slug}`
    if (project === null || project === undefined || !project.length) return
    return project.map((proj, i) => (
      <PresCard
        presTitle={fullPath}
        key={i}
        link={proj.projectLink}
        title={proj.projectTitle}
        image={proj.projectImage}
      />
    ))
  }

  return (
    <Layout>
      <Presentation>
        <BannerImage props={source.introBanner} />
        <>
          <LogoHolder>
            <Link to="/">
              <LandingLogo />
            </Link>
          </LogoHolder>
          <TextBox>
            <div>
              <BlockContent
                blocks={source._rawIntroText}
                serializers={serializers}
              />
            </div>
          </TextBox>
        </>
        {source._rawSections.map((section, i) => {
          if (section._type === "bannerImages") {
            return <BannerImage props={section} />
          }
          if (section._type === "textBlock") {
            return (
              <>
                <TextBox key={section._key}>
                  <div>
                    <BlockContent blocks={section.textBock} serializers={serializers} />
                  </div>
                </TextBox>
              </>
            )
          }
          if ((section._type = "projects")) {
            if (section.projectBlock === "video") {
              return (
                <ProjectDiv>
                  {makeCards(source.videoProjects, source.slug.current)}
                </ProjectDiv>
              )
            }
            return (
              <ProjectDiv>
                {makeCards(source.photoProjects, source.slug.current)}
              </ProjectDiv>
            )
          }

          return null
        })}
        <LogoHolder>
          <Link to="/">
            <LandingLogo />
          </Link>
        </LogoHolder>
      </Presentation>
    </Layout>
  )
}

export default PresentationPost

export const pageQuery = graphql`
  query($slug: String) {
    sanityPresentation(slug: { current: { eq: $slug } }) {
      slug {
        current
      }
      sections {
        ... on SanityBannerImages {
          _key
          _type
          asset {
            fluid {
              src
            }
          }
        }
        ... on SanityTextBlock {
          _key
          _type
          textBock {
            sanityChildren {
              marks
              text
            }
          }
        }
        ... on SanityProjectBlock {
          _key
          _type
          projectBlock
        }
      }
      _rawSections(resolveReferences: { maxDepth: 10 })
      introBanner {
        asset {
          fluid {
            ...GatsbySanityImageFluid
          }
        }
      }
      _rawIntroText
      videoProjects {
        projectImage {
          asset {
            fluid {
              ...GatsbySanityImageFluid
            }
          }
        }
        projectLink
        projectTitle
      }
      photoProjects {
        projectImage {
          asset {
            fluid {
              ...GatsbySanityImageFluid
            }
          }
        }
        projectLink
        projectTitle
      }
    }
  }
`
