import React from "react"
import Img from "gatsby-image/withIEPolyfill"
import { imageUrlFor } from "../../libs/imageUrls"
import styled from "styled-components"

const Banner = styled.div`
  width: 100vw;
  top: 0;
  left: 0;
  position: relative;
`

const BannerImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  @media (max-width: 650px) {
    object-fit: contain;
  }
`
const IntroBanner = styled(Img)`
  width: 100%;
  height: 100%;
`
const Banners = ({ props }) => {
  if (!props._type) {
    return (
      <Banner>
        <IntroBanner fluid={props.asset.fluid} objectFit={"cover"} />
      </Banner>
    )
  }

  return (
    <Banner>
      <BannerImage
        src={imageUrlFor(props.asset)
          .width(1300)
          .height(600)
          .auto("format")
          .url()}
        // srcSet={imageUrlFor(props.asset)
        //   .width(375)
        //   .quality(75)
        //   .auto("format")
        //   .url()}
      />
    </Banner>
  )
}

export default Banners
